import styles from './VBlog.module.css'

const VBlog = () => {
  return (
    <div className={styles.section}>
        VBlog
    </div>
  )
}

export default VBlog