import styles from './Vlayout.module.css'
import { useEffect, useRef, useState } from 'react'
import { Link, Outlet } from "react-router-dom"
// 
import Background from './common/Background'
import VNav from "./common/VNav"
import VFooter from "./common/VFooter"
// Images
import InlineSVG from 'react-inlinesvg'
import hector from './assets/img/other/hector.svg'

// Helmet
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';


const VLayout = () => {
    const isDefaultThemeColor = useSelector(state => state.helmet.theme_color.isDefault);
    const defaultThemeColor = useSelector(state => state.helmet.theme_color.defaultColor);
    const newThemeColor = useSelector(state => state.helmet.theme_color.newColor);

    // TIME
    const [serverTime, setServerTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
        setServerTime(new Date());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const formatTime = time => {
        const dateString = time.toString();
        const index = dateString.indexOf('(');
        return index !== -1 ? dateString.slice(0, index).trim() : dateString;
    };
    


    // 

    // const svgRef = useRef(null);

    // useEffect(() => {
    //     const svgElement = svgRef.current;

    //     function getRandomPosition() {
    //     const maxX = window.innerWidth - svgElement.clientWidth;
    //     const maxY = window.innerHeight - svgElement.clientHeight;

    //     const randomX = Math.random() * maxX;
    //     const randomY = Math.random() * maxY;

    //     return { x: randomX, y: randomY };
    //     }

    //     function moveElement() {
    //     const { x, y } = getRandomPosition();

    //     // Apply movement with smooth transition
    //     svgElement.style.transition = `transform ${Math.random() * 2 + 2}s ease-in-out`; // Random duration between 2s and 4s
    //     svgElement.style.transform = `translate(${x}px, ${y}px)`;
    //     }

    //     // Move the SVG to a new random position every 3 to 5 seconds
    //     const intervalId = setInterval(moveElement, Math.random() * 2000 + 3000);

    //     // Cleanup on component unmount
    //     return () => clearInterval(intervalId);
    // }, []);

    // 
    const content = (
        <>
            {isDefaultThemeColor
                ?   <Helmet>
                    <meta name="theme-color" content={defaultThemeColor} />
                    </Helmet>
                : <Helmet>
                        <meta name="theme-color" content={newThemeColor} />
                    </Helmet>
            }
            
            <div className={styles.v_layout}>
                    <Background />
                    {/* <div>
                    <div>
                    <svg
    ref={svgRef}
    className={styles.flyAround}
    width="104"
    height="116"
    viewBox="0 0 104 116"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.98633 53.7937C9.98633 46.2337 13.1962 39.0137 18.8562 34.0037C25.7662 27.8837 36.8263 21.3037 52.4363 21.3037C66.6463 21.3037 76.8662 26.3337 83.6162 31.4937C90.1662 36.5037 94.0063 44.2837 94.0063 52.5337V80.3237C94.0063 85.9937 91.1962 93.7037 87.8162 98.2537C81.8262 106.314 71.5163 115.054 54.2063 115.054C33.8663 115.054 25.4763 108.034 17.9463 100.904C12.6663 95.9138 9.98633 87.3938 9.98633 80.1338V53.8037V53.7937Z"
      fill="url(#paint0_linear_1683_140687)"
      stroke="#CCCCCC"
      strokeMiterlimit="10"
    />
    <path
      d="M22.5859 56.2441L23.0559 76.7842C23.0559 82.4042 24.236 88.0043 28.406 91.8643C34.356 97.3743 40.2459 96.2742 56.3159 96.2742C69.9859 96.2742 79.2959 96.3642 84.5659 90.7542C87.6259 87.4942 88.9561 81.1442 88.9561 76.7542V55.2542C88.9561 49.8642 86.7959 44.7441 83.0159 40.9441C82.0059 39.9341 80.7861 39.1642 79.4761 38.5842C73.3961 35.9242 65.6961 33.9541 56.2161 33.9541C46.7361 33.9541 38.226 36.2943 31.886 39.3843C31.056 39.7843 30.2959 40.3141 29.5959 40.9141C25.1259 44.7941 22.5859 50.3741 22.5859 56.2241V56.2441Z"
      fill="url(#paint1_radial_1683_140687)"
      stroke="black"
      strokeWidth="1.07"
      strokeMiterlimit="10"
    />
    <path
      d="M39.0254 75.374L47.0454 80.6541L40.3354 85.2839"
      stroke="#66CCCC"
      strokeWidth="1.07"
      strokeMiterlimit="10"
    />
    <path
      d="M78.0356 75.374L70.0156 80.6541L76.7258 85.2839"
      stroke="#66CCCC"
      strokeWidth="1.07"
      strokeMiterlimit="10"
    />
    <path
      d="M54.8657 24.7041V12.9043H50.4458V24.7041C49.3758 25.0241 48.6758 25.5641 48.6758 26.1741C48.6758 27.1541 50.4558 27.9441 52.6558 27.9441C54.8558 27.9441 56.6357 27.1541 56.6357 26.1741C56.6357 25.5641 55.9357 25.0241 54.8657 24.7041Z"
      fill="url(#paint2_linear_1683_140687)"
      stroke="#CCCCCC"
      strokeMiterlimit="10"
    />
    <path
      d="M55.375 9.28396C55.375 9.28396 95.665 1.68404 98.365 1.11404C103.055 0.124036 106.255 5.86404 100.005 7.36404C93.2051 8.99404 55.7351 10.9939 55.7351 10.9939L55.375 9.27395V9.28396Z"
      fill="url(#paint3_linear_1683_140687)"
      stroke="#CCCCCC"
      strokeMiterlimit="10"
    />
    <path
      d="M44.9961 12.9043C44.9961 8.8343 48.296 5.53418 52.366 5.53418C56.436 5.53418 59.7361 8.8343 59.7361 12.9043H44.9961Z"
      fill="url(#paint4_linear_1683_140687)"
      stroke="#CCCCCC"
      strokeMiterlimit="10"
    />
    <path
      d="M48.5369 13.4341C50.6521 13.4341 52.3669 12.3462 52.3669 11.0042C52.3669 9.6621 50.6521 8.57422 48.5369 8.57422C46.4216 8.57422 44.707 9.6621 44.707 11.0042C44.707 12.3462 46.4216 13.4341 48.5369 13.4341Z"
      fill="url(#paint5_radial_1683_140687)"
    />
    <path
      d="M48.676 11.0439C48.676 10.5239 48.2461 10.094 47.7261 10.114C41.5561 10.244 7.43601 10.9738 4.92601 10.9738C0.136013 10.9738 -1.81405 17.2538 4.61595 17.4338C11.046 17.6138 42.496 12.8138 47.896 11.9738C48.356 11.9038 48.686 11.5139 48.686 11.0539L48.676 11.0439Z"
      fill="url(#paint6_linear_1683_140687)"
      stroke="#CCCCCC"
      strokeMiterlimit="10"
    />
    <path
      d="M56.1259 8.01369C55.2559 8.06369 56.2459 8.99368 56.5959 9.75368C56.9459 10.5137 56.4458 11.8237 58.0158 12.0037C59.5858 12.1837 58.2561 9.55368 57.7461 9.00368C57.2361 8.45368 57.1559 7.95369 56.1159 8.01369H56.1259Z"
      fill="#F2F2F2"
    />
    <path
      d="M54.3264 13.4336H52.8965V27.7837H54.3264V13.4336Z"
      fill="url(#paint7_linear_1683_140687)"
    />
    <path
      style={{ mixBlendMode: 'overlay' }}
      opacity="0.5"
      d="M84.4863 7.59391C84.4863 7.59391 98.8762 4.88374 99.8362 4.67374C101.516 4.32374 102.656 6.37397 100.426 6.90397C97.9963 7.48397 84.6162 8.20377 84.6162 8.20377L84.4863 7.59391Z"
      fill="#006AB6"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1683_140687"
        x1="37.6561"
        y1="58.7959"
        x2="65.2499"
        y2="102.903"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ABEBE3" />
        <stop offset="1" stopColor="#0075A8" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_1683_140687"
        cx="0"
        cy="0"
        r="1"
        gradientTransform="translate(56.5093 61.1182) rotate(132.649) scale(54.4856)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7DF7E1" />
        <stop offset="1" stopColor="#6A6A6A" />
      </radialGradient>
      <linearGradient
        id="paint2_linear_1683_140687"
        x1="54.7718"
        y1="19.8314"
        x2="54.7718"
        y2="27.6488"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B6EBE8" />
        <stop offset="1" stopColor="#002A3B" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1683_140687"
        x1="85.0675"
        y1="2.26037"
        x2="58.5875"
        y2="2.26037"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8A5C" />
        <stop offset="1" stopColor="#FFBF72" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1683_140687"
        x1="52.5867"
        y1="7.93187"
        x2="52.5867"
        y2="16.9459"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#007D9C" />
        <stop offset="1" stopColor="#003D5B" />
      </linearGradient>
      <radialGradient
        id="paint5_radial_1683_140687"
        cx="0"
        cy="0"
        r="1"
        gradientTransform="translate(50.5173 11.1902) rotate(89.1773) scale(3.23568)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#003D5B" />
        <stop offset="1" stopColor="#007D9C" />
      </radialGradient>
      <linearGradient
        id="paint6_linear_1683_140687"
        x1="44.8965"
        y1="9.12431"
        x2="54.5645"
        y2="17.8912"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0099CC" />
        <stop offset="1" stopColor="#004F79" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_1683_140687"
        x1="53.8323"
        y1="8.67504"
        x2="53.8323"
        y2="27.6911"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9CF4F0" />
        <stop offset="1" stopColor="#006A74" />
      </linearGradient>
    </defs>
  </svg>

                    </div>
                    </div> */}

                    <VNav />
                    <Outlet />
                    <VFooter />
                    <div className={styles.main}>
                        <p className={styles.text}>Server Time: {formatTime(serverTime)}</p>
                    
                        <p className={styles.text}>Author: Marat Shakeshev [Version 1.0.234.5678]</p>
                        <p className={styles.text}>(c) ArcTeach. All rights reserved.</p>
                        
                        <p className={`${styles.text} ${styles.caret}`}>
                        {/* C:\{`>`} */}
                        (admin) shakeshev@Marats-MacBook-Pro ~ % 
                        </p>  
                    </div>  
            </div>
        </>
    )

    return content
}

export default VLayout