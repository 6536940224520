import styles from './Breadcrumbs.module.css'
import { Link } from 'react-router-dom';
// Icons
import arrowLeftLong from './../assets/img/icons/arrowLeftLong.svg'

const Breadcrumbs = ({ pageTitle }) => {
    // Determine the href value based on history

    return (
        <div className={styles.section}>
            <Link to='/' >
                <img src={arrowLeftLong} alt='arrow left' />
            </Link>
            <h2 className={styles.title}>{pageTitle}</h2>
        </div>
    
    )
}

export default Breadcrumbs