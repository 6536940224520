import styles from './V2Company.module.css'
// Components
import SECTION_TITLE from './SECTION_TITLE'
import V2FEATURE from './V2FEATURE'

const V2_Company = () => {
  return (
    <div className={styles.section}>
        <SECTION_TITLE title="О компании" />
        <div className={styles.body}>
            <V2FEATURE title='Индивидуальный подход и креативность' description='Предлагаем уникальные решения, которые соответствуют вашим требованиям и помогают выделиться на фоне конкурентов.' />
            <V2FEATURE title='Индивидуальный подход и креативность' description='Предлагаем уникальные решения, которые соответствуют вашим требованиям и помогают выделиться на фоне конкурентов.' />
            <V2FEATURE title='Индивидуальный подход и креативность' description='Предлагаем уникальные решения, которые соответствуют вашим требованиям и помогают выделиться на фоне конкурентов.' />
        </div>
        </div>
        
  )
}

export default V2_Company