import styles from './VMain.module.css'
// Components
import V1Banner from './V1Banner'
import V2Company from './V2Company'
import V3WebDevelopment from './V3WebDevelopment'
import V4Application from './V4Application'

const VMain = () => {
  return (
    <>
      <V1Banner />
      <V2Company />
      <V3WebDevelopment />
      <V4Application />
    </>
  )
}

export default VMain