import styles from './VFooter.module.css'
// Icons
import email from './../assets/img/footer/email.svg'
import phone from './../assets/img/footer/phone.svg'
import time from './../assets/img/footer/time.svg'
import address from './../assets/img/footer/address.svg'

import map_yandex from './../assets/img/footer/maps/map_yandex.svg'
import map_google from './../assets/img/footer/maps/map_google.svg'
import map_2gis from './../assets/img/footer/maps/map_2gis.svg'

import telegram from './../assets/img/footer/social/telegram.svg'
import whatsapp from './../assets/img/footer/social/whatapp.svg'
import instagram from './../assets/img/footer/social/instagram.svg'
import tiktok from './../assets/img/footer/social/tiktok.svg'
import facebook from './../assets/img/footer/social/facebook.svg'
import youtube from './../assets/img/footer/social/youtube.svg'

const VFooter = () => {   

    const Title = ({ title }) => {
        return <h4 className={styles.title}>{title}</h4>
    }
    
    return (
      <footer className={styles.footer}>
            <div className={styles.upper}>
                    {/* left */}
                    <div className={styles.left}>
                                    <Title title='Контакты' />

                                    <div className={styles.record}>
                                        <img className={styles.contact_icon} src={email} alt='phone' />
                                        <p>info@shakeshev.com</p>
                                    </div>
                                    
                                    <div className={styles.record}>
                                        <img className={styles.contact_icon} src={phone} alt='phone' />
                                        <p>+7 (778) 157 88 00</p>
                                    </div>

                                    <div className={styles.buttons_block}>
                                        <button className={styles.telegram}>
                                            <img src={telegram} alt='telegram' />
                                            Telegram
                                        </button>
                                        <button className={styles.whatsapp}>
                                            <img src={whatsapp} alt='whatsapp' />
                                            WhatsApp
                                        </button>    
                                    </div>
                                    
                                </div>
                                {/* middle */}
                                <div className={styles.middle}>
                                    <Title title='Контакты' />

                                    <div className={styles.record}>
                                        <img className={styles.contact_icon} src={time} alt='phone' />
                                        <div className={styles.working_hours}>
                                            <p>ПН-ПТ</p>
                                            <p>9.00am - 6.00pm</p>
                                        </div>
                                        <div>
                                            <p>ПН-ПТ</p>
                                            <p>Выходной</p>
                                        </div>
                                    </div>

                                </div>
                                {/* right */}
                                <div className={styles.right}>
                                    <Title title='Контакты' />

                                    <div className={styles.record}>
                                        <img className={styles.contact_icon} src={address} alt='phone' />
                                        <div>
                                            <p>просп. Сейфуллина 597</p>
                                            <p>подъезд 3, 7 этаж, 23 каб.</p>
                                        </div>
                                    </div>

                                    <div className={styles.country}>
                                        <p>г.Алматы, 050022</p>
                                        <p>Республика Казахстан</p>
                                    </div>

                                    <div className={styles.buttons_maps_block}>
                                        <div className={styles.map_item}>
                                            <img src={map_google} alt='google' />
                                            <p>Google</p>
                                        </div>
                                        <div className={styles.map_item}>
                                            <img src={map_2gis} alt='2gis' />
                                            <p>2GIS</p>
                                        </div>
                                        <div className={styles.map_item}>
                                            <img src={map_yandex} alt='yandex' />
                                            <p>Yandex</p>
                                        </div>
                                    </div>

                                    <div className={styles.map_scheme_block}>
                                        <button className={styles.map_scheme_button}>Карта схемы проезда</button>
                                    </div>
                                    
                                </div>
            </div>
            

            <div className={styles.bottom_block}>
                <div className={styles.bottom_left}>
                    <h5 className={styles.label_follow}>Follow Us</h5>
                    <img src={tiktok} alt='tiktok' />
                    <img src={instagram} alt='instagram' />
                    <img src={facebook} alt='facebook' />
                    <img src={youtube} alt='youtube' />
                </div>
                <div className={styles.bottom_right}>
                    <button>Договор оферты</button>
                    <button>Условия пользования</button>
                    <button>Политика конфиденциальности</button>
                </div>
            </div>
      </footer>
    )
}

export default VFooter