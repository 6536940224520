import styles from './V3WebDevelopment.module.css'
// Components
import SECTION_TITLE from './SECTION_TITLE'
// Icons
import InlineSVG from 'react-inlinesvg'
import card_item from './../../assets/img/other/card_item.svg'
// Items
import crm from './../../assets/img/by_page/main/crm.png'
import characters from './../../assets/img/by_page/main/characters.svg'

const V3CARD = ({ img, title }) => {
    return <div className={styles.card}>
                <InlineSVG className={styles.card_bg} src={card_item} />
                <div className={styles.img_block}>
                    <img className={styles.card_img} src={img} alt='crm' />
                </div>
                
                <div className={styles.buttons_block}>
                    <h3 className={styles.cardTitle}>{title}</h3>
                    <div className={styles.buttons_info_portfolio}>
                        <button className={`${styles.btn} ${styles.btn_info}`}>
                            инфо
                        </button>
                        <button className={`${styles.btn} ${styles.btn_portfolio}`}>
                            портфолио
                        </button>
                    </div>
                    <button className={`${styles.btn} ${styles.btn_order}`}>
                        заказать
                    </button>
                </div>
            </div>
}
const V3WebDevelopment = () => {
    return (
        <>
        
            {/* WEB DEVELOPMENT */}
            <div className={styles.section}>
                <SECTION_TITLE title="Веб разработка" />
                <div className={styles.body}>
                <div className={styles.row}>
                        <V3CARD img={crm} title='CRM' />
                        <V3CARD img={characters} title='Лэндинг'  />
                        <V3CARD img={crm} title='Интернет-магазин' />
                        <V3CARD img={crm} title='Сайт-каталог' />
                    </div>
                    <div className={styles.row}>
                        <V3CARD img={crm} title='Сайт-портфолио' />
                        <V3CARD img={crm} title='Сайт-визитка' />
                        <V3CARD img={crm} title='Интернет портал' />
                        <V3CARD img={crm} title='SEO Продвижение' />
                    </div>

                </div>
            </div>
            {/* INTEGRATION */}
            <div className={styles.section}>
                <SECTION_TITLE title="Интеграция" />
                <div className={styles.body}>
                    <div className={styles.row}>
                        <V3CARD img={crm} title='1С'  />
                        <V3CARD img={characters} title='Онлайн платежи'  />
                    </div>
                </div>
            </div>
            {/* CHAT BOTS */}
            <div className={styles.section}>
                <SECTION_TITLE title="Чат боты" />
                <div className={styles.body}>
                    <div className={styles.row}>
                        <V3CARD img={crm} title='Чат GPT' />
                        <V3CARD img={characters} title='WhatsApp Bot' />
                        <V3CARD img={characters} title='Telegram Bot' />
                    </div>
                </div>
            </div>
            {/* DESIGN */}
            <div className={styles.section}>
                <SECTION_TITLE title="Дизайн" />
                <div className={styles.body}>
                    <div className={styles.row}>
                        <V3CARD img={characters} title='UX/UI' />
                        <V3CARD img={characters} title='Карточки маркетплейса' />
                        <V3CARD img={characters} title='2D Анимации' />
                        <V3CARD img={characters} title='3D Анимации' />
                    </div>
                </div>
            </div>
            {/* BRANDING */}
            <div className={styles.section}>
                <SECTION_TITLE title="Брендинг" />
                <div className={styles.body}>
                    <div className={styles.row}>
                        <V3CARD img={crm} title='Персонажи' />
                        <V3CARD img={characters} title='Логотипа' />
                        <V3CARD img={characters} title='Визитки' />
                    </div>
                </div>
            </div>
            {/* Mobile Applications */}
            <div className={styles.section}>
                <SECTION_TITLE title="Мобильные приложения" />
                <div className={styles.body}>
                    <div className={styles.row}>
                        <V3CARD img={crm} title='iOS' />
                        <V3CARD img={characters} title='Android' />
                        <V3CARD img={characters} title='PWA' />
                    </div>
                </div>
            </div>
            {/* Video / Audio / Photo */}
            <div className={styles.section}>
                <SECTION_TITLE title="Видео/Аудио/Фото" />
                <div className={styles.body}>
                    <div className={styles.row}>
                        <V3CARD img={crm} title='Предметная съемка' />
                        <V3CARD img={characters} title='Аудио озвучка' />
                        <V3CARD img={characters} title='Видеосъемка' />
                    </div>
                </div>
            </div>
        </> 
      )
}

export default V3WebDevelopment