import styles from './V1Banner.module.css'
// icons
import InlineSvg from 'react-inlinesvg'
import card_banner from './../../assets/img/other/card_banner.svg'
import pic from './../../assets/img/other/uxui.png'

const V1Banner = () => {
  return (
          <div className={styles.main}>

                <div className={styles.left}>
                    <h1 className={styles.title}>Shakeshev</h1>
                    <h2 className={styles.subtitle}>IT & Design</h2>
                </div>

                <div className={styles.right}>
                      <InlineSvg className={`selectDisable ${styles.card_bg}`} src={card_banner} />
                      <div className={styles.header}>
                            <div className={styles.headerLeft}>
                                <p className={styles.text}>Мастер Класс</p>
                                <p className={styles.subtext}>UX-UI дизайнер</p>
                            </div>
                            <div className={styles.headerRight}>
                                <p className={styles.text}>15-18</p>
                                <p className={styles.subtext}>авг</p>
                            </div>
                      </div>
                      <div className={styles.body}>
                        <img src={pic} alt='course pic' />
                      </div>
                      <div className={styles.footer}>
                          <button className={styles.btn}>
                            оставить заявку
                          </button>
                      </div>
                      
                </div>
              
            </div>
  )
}

export default V1Banner