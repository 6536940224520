import styles from './VFaq.module.css'

const VFaq = () => {
  return (
    <div className={styles.section}>
        VFaq
    </div>
  )
}

export default VFaq