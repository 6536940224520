import styles from './V4Application.module.css'

const V4Application = () => {
  return (
    <div className={styles.block}>
        <div className={styles.content}>
            <p className={styles.text}>Оставьте заявку на БЕСПЛАТНУЮ консультацию</p>
            <p className={styles.subtext}>Мы составим для Вас индивидуальное предложение в кратчайшие сроки</p>
        </div>
        <button className={styles.btn}>Оставить заявку</button>
    </div>
  )
}

export default V4Application