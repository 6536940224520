import Breadcrumbs from '../../common/Breadcrumbs'
import styles from './VAbout.module.css'
// Icons
import logo from './../../assets/img/other/logo.jpg'
import Marat from './../../assets/img/by_page/about/Marat.png'

// Components
import V2FEATURE from '../VMain/V2FEATURE'
import V4Application from '../VMain/V4Application'

const Employee = ({ photo, name, title, exp }) => {
  return <div className={styles.team_container}>
            <img className={styles.photo} src={photo} alt='Marat' />
            <div className={styles.label_block}>
              <div className={styles.label_left}>
                  <p className={styles.team_name}>{name}</p>
                  <p className={styles.team_title}>{title}</p>
              </div>
              <div className={styles.label_right}>
                  <p className={styles.team_exp}>{exp}</p>
              </div>
            </div>
          </div>
} 

const VAbout = () => {
  return (
    <div className={styles.section}>
        <Breadcrumbs pageTitle='О Компании' />

        <div className={styles.body}>
          <div className={styles.left}>
             <img className={styles.logo} src={logo} alt='pic' />
              <p className={styles.text}>SHAKESHEV — это IT-компания, предлагающая инновационные решения в веб-дизайне, разработке сайтов и приложений, создании чат-ботов и моушен-дизайне. Мы предоставляем уникальные и надежные услуги, используя современные технологии и индивидуальный подход, чтобы ваш бизнес выделялся и развивался.</p>
          </div>
          <div className={styles.right}>
              <V2FEATURE style={styles.feature} title='Индивидуальный подход и креативность' description='Предлагаем уникальные решения, которые соответствуют вашим требованиям и помогают выделиться на фоне конкурентов.' />
              <V2FEATURE style={styles.feature} title='Индивидуальный подход и креативность' description='Предлагаем уникальные решения, которые соответствуют вашим требованиям и помогают выделиться на фоне конкурентов.' />
              <V2FEATURE style={styles.feature} title='Индивидуальный подход и креативность' description='Предлагаем уникальные решения, которые соответствуют вашим требованиям и помогают выделиться на фоне конкурентов.' />
          </div>
        </div>

        <h2 className={styles.heading}>Кто мы?</h2>
        <div className={styles.row}>

          <div className={styles.fact_container}>
            <h3 className={styles.fact_label}>История компании</h3>
            <p className={styles.fact_text}>SHAKESHEV была основана в 2020 году, с целью предоставления инновационных IT-услуг, которые помогают бизнесам эффективно использовать цифровые технологии.</p>
          </div>

          <div className={styles.fact_container}>
            <h3 className={styles.fact_label}>История компании</h3>
            <p className={styles.fact_text}>SHAKESHEV была основана в 2020 году, с целью предоставления инновационных IT-услуг, которые помогают бизнесам эффективно использовать цифровые технологии.</p>
          </div>

          <div className={styles.fact_container}>
            <h3 className={styles.fact_label}>История компании</h3>
            <p className={styles.fact_text}>SHAKESHEV была основана в 2020 году, с целью предоставления инновационных IT-услуг, которые помогают бизнесам эффективно использовать цифровые технологии.</p>
          </div>

        </div>



        {/*  */}
        <h2 className={styles.heading}>Команда</h2>
        <div className={styles.team_row}>
            <Employee photo={Marat} name='Marat' title='Основатель' exp='Опыт 13 лет' />
            <Employee photo={Marat} name='Marat' title='Основатель' exp='Опыт 13 лет' />
            <Employee photo={Marat} name='Marat' title='Основатель' exp='Опыт 13 лет' />


        </div>

        {/*  */}
        <V4Application />
    </div>
  )
}

export default VAbout