// import React, { useRef, useEffect } from 'react';
// import _ from 'lodash';
// import styles from './Background.module.css';

// const GridWrapperElementId = "canvas-wrapper";
// const CellsCanvasContextId = "canvas-grid-cells";
// const LinesCanvasContextId = "canvas-grid-lines";

// function getDocumentHeight() {
//   return Math.max(
//     document.documentElement.clientHeight,
//     window.innerHeight || 0
//   );
// }

// function getDocumentWidth() {
//   return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
// }

// function randomRange(min, max) {
//   return Math.random() * (max - min) + min;
// }

// class AnimatedLine {
//   constructor(context, left, top, gridHeight) {
//     this.context = context;
//     this.color = "hsla(180, 100%, 75%, 0.075)";
//     this.gridHeight = gridHeight;
//     this.height = randomRange(25, 150);
//     this.speed = randomRange(1, 5);
//     this.left = left;
//     this.top = top;
//     this.width = 3;
//   }

//   render() {
//     this.context.fillStyle = this.color;
//     this.context.fillRect(this.left, this.top, this.width, this.height);
//   }

//   update() {
//     this.top -= this.speed;
//     if (this.top < -this.gridHeight) {
//       this.top = this.gridHeight;
//       this.height = randomRange(25, 150);
//       this.speed = randomRange(1, 5);
//     }
//     this.render();
//   }
// }

// class BackgroundAnimation {
//   constructor(cellsCanvasRef, linesCanvasRef) {
//     this.cellsCanvasRef = cellsCanvasRef;
//     this.linesCanvasRef = linesCanvasRef;
//     this.animatedLines = [];
//     this.gridSize = {
//       width: getDocumentWidth(),
//       height: getDocumentHeight()
//     };
//     this.gridCellSize = { width: 30, height: 30, radius: 2 };
//     this.firstRender = true;
//   }

//   createAnimatedLines() {
//     this.animatedLines = [];
//     for (let x = this.gridCellSize.width; x <= this.gridSize.width; x += this.gridCellSize.width) {
//       let y = this.firstRender
//         ? randomRange(this.gridSize.height * 2, this.gridSize.height * 4)
//         : randomRange(0, this.gridSize.height * 2);
//       this.animatedLines.push(
//         new AnimatedLine(
//           this.linesCanvasRef.current.getContext("2d"),
//           x,
//           y,
//           this.gridSize.height
//         )
//       );
//     }
//     this.firstRender = false;
//   }

//   drawGridDots() {
//     const fillStyle = "hsla(180, 100%, 75%, 0.05)";
//     const ctx = this.cellsCanvasRef.current.getContext("2d");
//     for (let x = this.gridCellSize.width; x < this.gridSize.width; x += this.gridCellSize.width) {
//       for (let y = this.gridCellSize.height; y < this.gridSize.height; y += this.gridCellSize.height) {
//         ctx.beginPath();
//         ctx.arc(x, y, 1, 0, Math.PI * 2);
//         ctx.fillStyle = fillStyle;
//         ctx.fill();
//         ctx.strokeStyle = fillStyle;
//         ctx.stroke();
//       }
//     }
//   }

//   drawGridFrame(height, size) {
//     const ctx = this.cellsCanvasRef.current.getContext("2d");
//     ctx.clearRect(0, 0, this.gridSize.width, this.gridSize.height);
//     ctx.strokeStyle = "hsla(180, 100%, 75%, 0.05)";
//     ctx.beginPath();
//     ctx.setLineDash([]);
//     for (let x = 0; x <= this.gridSize.width; x += this.gridCellSize.width) {
//       ctx.moveTo(x, 0);
//       ctx.lineTo(x, height);
//     }
//     ctx.stroke();
//     ctx.beginPath();
//     ctx.setLineDash([size, size]);
//     for (let y = 0; y <= height; y += this.gridCellSize.height) {
//       ctx.moveTo(0, y);
//       ctx.lineTo(this.gridSize.width, y);
//     }
//     ctx.stroke();
//   }

//   drawGrid() {
//     this.drawGridFrame(this.gridSize.height, 5);
//     this.drawGridDots();
//   }

//   onAnimationFrame() {
//     requestAnimationFrame(() => this.onAnimationFrame());
//     const ctx = this.linesCanvasRef.current.getContext("2d");
//     ctx.clearRect(0, 0, this.gridSize.width, this.gridSize.height);
//     this.animatedLines.forEach(line => line.update());
//   }

//   onResize() {
//     this.gridSize.width = getDocumentWidth();
//     this.gridSize.height = getDocumentHeight();
//     this.resizeGrid();
//   }

//   resizeGrid() {
//     this.cellsCanvasRef.current.width = this.linesCanvasRef.current.width = this.gridSize.width;
//     this.cellsCanvasRef.current.height = this.linesCanvasRef.current.height = this.gridSize.height;
//     this.createAnimatedLines();
//     this.drawGrid();
//   }

//   showAnimated() {
//     const gridWrapper = document.getElementById(GridWrapperElementId);
//     if (!gridWrapper) return;
//     this.resizeGrid();
//     gridWrapper.animate(
//       { opacity: [0, 1] },
//       { duration: 1000 }
//     ).onfinish = () => {
//       gridWrapper.style.opacity = "1";
//     };
//     this.drawGrid();
//   }

//   initialize() {
//     const gridWrapper = document.getElementById(GridWrapperElementId);
//     if (!gridWrapper) return;
//     this.cellsCanvasRef.current = document.getElementById(CellsCanvasContextId);
//     this.linesCanvasRef.current = document.getElementById(LinesCanvasContextId);
//     window.addEventListener("resize", _.throttle(() => this.onResize(), 100));
//     this.showAnimated();
//     setTimeout(() => this.onAnimationFrame(), 1500);
//   }
// }

// const Background = () => {
//   const cellsCanvasRef = useRef(null);
//   const linesCanvasRef = useRef(null);

//   useEffect(() => {
//     const backgroundAnimation = new BackgroundAnimation(cellsCanvasRef, linesCanvasRef);
//     backgroundAnimation.initialize();
//     return () => {
//       window.removeEventListener("resize", _.throttle(() => backgroundAnimation.onResize(), 100));
//     };
//   }, []);

//   return (
//     <div id={GridWrapperElementId} className={styles.canvasWrapper}>
//       <canvas id={CellsCanvasContextId} className={styles.canvasGridCells} ref={cellsCanvasRef} />
//       <canvas id={LinesCanvasContextId} className={styles.canvasGridLines} ref={linesCanvasRef} />
//     </div>
//   );
// };

// export default Background;



import React, { useRef, useEffect } from 'react';
import _ from 'lodash';
import styles from './Background.module.css';

const GridWrapperElementId = "canvas-wrapper";
const CellsCanvasContextId = "canvas-grid-cells";
const LinesCanvasContextId = "canvas-grid-lines";

function getElementDimensions(element) {
  return {
    width: element.clientWidth,
    height: element.clientHeight
  };
}

function randomRange(min, max) {
  return Math.random() * (max - min) + min;
}

class AnimatedLine {
  constructor(context, left, top, gridHeight) {
    this.context = context;
    this.color = "hsla(180, 100%, 75%, 0.075)";
    this.gridHeight = gridHeight;
    this.height = randomRange(25, 150);
    this.speed = randomRange(1, 5);
    this.left = left;
    this.top = top;
    this.width = 5;
  }

  render() {
    this.context.fillStyle = this.color;
    this.context.fillRect(this.left, this.top, this.width, this.height);
  }

  update() {
    this.top -= this.speed;
    if (this.top < -this.gridHeight) {
      this.top = this.gridHeight;
      this.height = randomRange(25, 150);
      this.speed = randomRange(1, 5);
    }
    this.render();
  }
}

class BackgroundAnimation {
  constructor(cellsCanvasRef, linesCanvasRef) {
    this.cellsCanvasRef = cellsCanvasRef;
    this.linesCanvasRef = linesCanvasRef;
    this.animatedLines = [];
    this.gridSize = {
      width: 0,
      height: 0
    };
    this.gridCellSize = { width: 30, height: 30, radius: 2 };
    this.firstRender = true;
  }

  createAnimatedLines() {
    this.animatedLines = [];
    for (let x = this.gridCellSize.width; x <= this.gridSize.width; x += this.gridCellSize.width / 3) {
      let y = this.firstRender
        ? randomRange(this.gridSize.height * 2, this.gridSize.height * 4)
        : randomRange(0, this.gridSize.height * 2);
      this.animatedLines.push(
        new AnimatedLine(
          this.linesCanvasRef.current.getContext("2d"),
          x,
          y,
          this.gridSize.height
        )
      );
    }
    this.firstRender = false;
  }

  drawGridDots() {
    const fillStyle = "hsla(180, 100%, 75%, 0.05)";
    const ctx = this.cellsCanvasRef.current.getContext("2d");
    for (let x = this.gridCellSize.width; x < this.gridSize.width; x += this.gridCellSize.width) {
      for (let y = this.gridCellSize.height; y < this.gridSize.height; y += this.gridCellSize.height) {
        ctx.beginPath();
        ctx.arc(x, y, 1, 0, Math.PI * 2);
        ctx.fillStyle = fillStyle;
        ctx.fill();
        ctx.strokeStyle = fillStyle;
        ctx.stroke();
      }
    }
  }

  drawGridFrame(height, size) {
    const ctx = this.cellsCanvasRef.current.getContext("2d");
    ctx.clearRect(0, 0, this.gridSize.width, this.gridSize.height);
    ctx.strokeStyle = "hsla(180, 100%, 75%, 0.05)";
    ctx.beginPath();
    ctx.setLineDash([]);
    for (let x = 0; x <= this.gridSize.width; x += this.gridCellSize.width) {
      ctx.moveTo(x, 0);
      ctx.lineTo(x, height);
    }
    ctx.stroke();
    ctx.beginPath();
    ctx.setLineDash([size, size]);
    for (let y = 0; y <= height; y += this.gridCellSize.height) {
      ctx.moveTo(0, y);
      ctx.lineTo(this.gridSize.width, y);
    }
    ctx.stroke();
  }

  drawGrid() {
    this.drawGridFrame(this.gridSize.height, 5);
    this.drawGridDots();
  }

  onAnimationFrame() {
    requestAnimationFrame(() => this.onAnimationFrame());
    const ctx = this.linesCanvasRef.current.getContext("2d");
    ctx.clearRect(0, 0, this.gridSize.width, this.gridSize.height);
    this.animatedLines.forEach(line => line.update());
  }

  onResize() {
    const gridWrapper = document.getElementById(GridWrapperElementId);
    if (!gridWrapper) return;

    const dimensions = getElementDimensions(gridWrapper);
    this.gridSize.width = dimensions.width;
    this.gridSize.height = dimensions.height;
    this.resizeGrid();
  }

  resizeGrid() {
    this.cellsCanvasRef.current.width = this.linesCanvasRef.current.width = this.gridSize.width;
    this.cellsCanvasRef.current.height = this.linesCanvasRef.current.height = this.gridSize.height;
    this.createAnimatedLines();
    this.drawGrid();
  }

  showAnimated() {
    const gridWrapper = document.getElementById(GridWrapperElementId);
    if (!gridWrapper) return;
    this.onResize();
    gridWrapper.animate(
      { opacity: [0, 1] },
      { duration: 1000 }
    ).onfinish = () => {
      gridWrapper.style.opacity = "1";
    };
    this.drawGrid();
  }

  initialize() {
    this.cellsCanvasRef.current = document.getElementById(CellsCanvasContextId);
    this.linesCanvasRef.current = document.getElementById(LinesCanvasContextId);
    window.addEventListener("resize", this.onResize.bind(this));
    this.showAnimated();
    setTimeout(() => this.onAnimationFrame(), 1500);
    
    // Trigger a resize after the component mounts to ensure correct dimensions
    setTimeout(() => {
      this.onResize();
    }, 100);
  }
}

const Background = () => {
  const cellsCanvasRef = useRef(null);
  const linesCanvasRef = useRef(null);

  useEffect(() => {
    const backgroundAnimation = new BackgroundAnimation(cellsCanvasRef, linesCanvasRef);
    backgroundAnimation.initialize();
    return () => {
      window.removeEventListener("resize", backgroundAnimation.onResize.bind(backgroundAnimation));
    };
  }, []);

  return (
    <div id={GridWrapperElementId} className={styles.canvasWrapper}>
      <canvas id={CellsCanvasContextId} className={styles.canvasGridCells} ref={cellsCanvasRef} />
      <canvas id={LinesCanvasContextId} className={styles.canvasGridLines} ref={linesCanvasRef} />
    </div>
  );
};

export default Background;
