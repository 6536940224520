import styles from './V2FEATURE.module.css'
// Icons
import InlineSvg from 'react-inlinesvg'
import tick from './../../assets/img/other/tick.svg'

const V2FEATURE = ({ title, description, style=false }) => {
  return (
            <div className={style ? style : styles.feature}>
                <InlineSvg className={styles.icon} src={tick} />
                <div className={styles.content}>
                    <h3 className={styles.title}>{title}</h3>
                    <p className={styles.description}>{description}</p>
                </div>
            </div>
        )
}

export default V2FEATURE